import * as React from 'react';
import { Seo } from '../components/Seo'
import { graphql } from 'gatsby'
import * as styles from '../styles/maintenance.module.scss';
import Modal from '../components/Modal/Modal';
import { Layout } from '../components/Layout';

const MaintenanceTemplate = ({ data }) => {
    if (!data) return null;
    const maintenanceDoc = data.prismicMaintenance.data;

    return (
        <Layout>
            <Seo
                title="Maintenance"
                description="We are sorry but our site is currently undergoing changes. Please check back in later."
            />
            <section className={styles.MaintenanceWrap}>
                {maintenanceDoc.image ? <img src={maintenanceDoc.image.url} alt={maintenanceDoc.image.alt} className={styles.MaintenanceLogo} /> : null}
                <div className={styles.MaintenanceContentWrap}>
                    {maintenanceDoc.title ? <h1 className={styles.MaintenanceTitle}>{maintenanceDoc.title.text}</h1> : null}
                    {maintenanceDoc.copy ? <p className={styles.MaintenanceCopy}>{maintenanceDoc.copy.text}</p> : null}
                </div>
            </section>
            <Modal type="contact" />
        </Layout>
    );
}

export const maintenanceQuery = graphql`
    query Maintenance {
        prismicMaintenance{
            data {
                title {
                    text
                },
                active,
                copy {
                    text
                },
                image {
                    url,
                    alt
                }
            }
        }
    } 
`

export default MaintenanceTemplate;