import React from 'react';
import * as styles from '../../styles/Modal.module.scss';
import ContactForm from '../Form/Contact';
import { useSelector } from 'react-redux';

const Modal = (props) => {

    let modalStyles = [styles.ModalWrap];
    const openModal = useSelector(state => state.openModal);

    if(openModal) {
        modalStyles.push(styles.ModalActive);
    }

    return (
        <div className={modalStyles.join(' ')}>
           <ContactForm />
        </div>
    )
}

export default Modal;